<template>
    <div class="produit" @click.stop="afficheProduit">
        <div class="bloc-image">
            <back-img-portail :dist-url="elementVignetteUrl" class="produit-image">
            </back-img-portail>
            <template v-if="portail.afficher_stock && element.categorie === 'article'">
                <template v-if="element.disponibilite !== 999999">
                    <span class="rupture-imminente" v-if="element.rupture_imminente === 1">Rupture imminente</span>
                    <span class="ruptureStock" v-else-if="element.disponibilite < element.quantite_minimale">Rupture de stock</span>
                    <span class="afficheStock" v-if="element.disponibilite >= element.quantite_minimale">Stock : {{element.disponibilite}}</span>
                </template>
            </template>
        </div>
        <div class="produit-title" :title="element.titre">{{ element.titre }}</div>
        <div class="produit-prix">
            <span v-if="comportePrixMultiples(element) && !estArticleACompleter(element)" class="apartir">A partir de</span>
            {{ prix }}
        </div>
        <div class="produit-select">
            <div class="produit-actions">
                <btn-theme type="button" small title="Ajouter au panier" @click="afficheProduit">Commander</btn-theme>
            </div>
        </div>
    </div>
</template>

<script>
    import MonnaieMixin from '../mixins/monnaie';
    import ElementMixin from '../mixins/element';
    import {mapGetters} from "vuex";
    import BtnTheme from "../components/theme/BtnTheme";
    import BackImgPortail from "./theme/BackImgPortail";

    export default {
        name: 'ElementProduit',
        components: {
          BackImgPortail,
            BtnTheme,
        },
        mixins: [
            MonnaieMixin,
            ElementMixin
        ],
        data: () => ({
            urlVignette: process.env.VUE_APP_API_URL + '/image/vignette/grande',
        }),
        props: {
            element: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                'panier',
                'portail'
            ]),
            elementVignetteUrl () {
                let vignette = this.element.vignette || 'nopicture.jpg';
                return this.urlVignette + '/' + encodeURI(vignette) + '?libelle=' + encodeURI(this.element.reference);
            },
            prix () {
                if (this.estArticleACompleter(this.element)) return 'Prix suivant contenu';
                if (this.prixMinimum(this.element) > 0) return this.formatMonetaire(this.prixMinimum(this.element));
                return ' ';
            }
        },
        methods: {
            afficheProduit () {
              this.$emit('add-to-cart', this.element);
            }
        }
    }
</script>

<style scoped lang="scss">
    .produit {
        width: 33.33%;
        display: inline-block;
        position: relative;
        outline: 1px solid #d6dadd;
        background: #f5f5f5;
        padding-bottom: 20px;
        text-align: center;
        & .produit-image {
            cursor: pointer;
            border-bottom: 1px solid #d6dadd;
            background-position: center center;
            -moz-background-size: contain;
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: white;
            height: 250px;
        }
        & .produit-title {
            margin-top: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 2em;
            line-height:1em;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
        & .produit-stock {
            padding: 5px 10px;
            margin: 0;
           // font-weight: normal;
            font-size: 10px;
           // display: block;
            font-weight: 400;
            background: #fffae3;
            overflow: hidden;
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            color: #5e5e5e;
            position: absolute;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        & .produit-prix {
            margin-top: 5px;
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            & .apartir {
                font-size: 0.80em;
            }
        }
        & .produit-select {
            padding-top: 0;
            & .produit-quantite {
                display: inline-block;
                margin: 0;
                text-align: center;
                padding: 0 5px 0 20px;
                height: 20px;
            }
            & .produit-actions {
                display: inline-block;
                margin: 0;
                text-align: center;
                padding: 0 0 5px 0;
            }
        }

        .bloc-image {
            position:relative;
            & .afficheStock {
                position: absolute;
                bottom: 0;
                right: 5px;
                font-size:0.7rem;
                text-shadow: 0 0 5px #FFFFFF;
                font-weight: 900;
            }
            & .ruptureStock,
            & .rupture-imminente{
                text-shadow: 0 0 5px #FFFFFF;

                /* Contraintes de position */
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                /* /Contraintes de position */

                /* Contraintes de hauteur et largeur */
                //height: fit-content;

                width: max-content;
                /* /Contraintes de hauteur et largeur */

                /* Style de paragraphe */
                color: #b40000;
                font-weight: 900;
                font-size: 2rem;
                /* /Style de paragrpahe */

                /* Positionnement */
                transform: rotate(-45deg);
                display: flex;
                align-items: center;
                /* /Positionnement */
            }
            .rupture-imminente {
                color: #ffc107 !important;
                text-shadow: 0 0 5px #000000;
            }
        }
    }

    @media (min-width: 1600px) {
        .produit {
            width: 20%;
        }
    }

    @media (max-width: 1400px) and (min-width: 920px) {
        .produit {
            width: 50% !important;
        }
    }

    @media (max-width: 919px) {
        .produit {
            max-width:300px;
            width:100%;
        }
    }
</style>