<template>
    <div class="boutique">
        <carousel v-if="!portail || !portail.carousel_desactive"></carousel>

        <div class="entete">
            <h2 v-if="menuActuel && !currentRecherche" v-html="(menuActuel&&menuActuel.slug === 'home') ? 'Notre sélection du moment' : menuActuel.libelle" class="titre"></h2>
            <h2 v-else-if="currentRecherche" v-html="'Recherche de : '+ currentRecherche" class="titre"></h2>
        </div>

        <v-snackbar
            top
            right
            centered
            color="primary"
            v-model="notificationVisible"
        >
            Votre choix a été ajouté au panier.
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="fermerNotification">
                    <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
<!--        <bandeau-titre :titre="`${currentMenu ? currentMenu.libelle : 'Recherche de: ' + currentRecherche }`" :onglet="true"></bandeau-titre>-->
        <div class="categorie" v-if="currentMenu || currentRecherche || elements">
            <div class="categorie-description" v-if="currentMenu && currentMenu.description" v-html="currentMenu.description"></div>
            <div class="categorie-description-courte" v-if="currentMenu && currentMenu.description_courte">
                {{ currentMenu.description_courte }}
            </div>

            <div class="d-flex flex-wrap justify-center categorie-contenu">
                <template v-for="element in sortedElements">
                    <element-produit v-if="element.type==='element'" :element="element" :key="element.id" v-on:add-to-cart="afficheProduit(element)"></element-produit>
                    <groupe-produit v-if="element.type==='groupe'" :groupe="element" :key="element.id" v-on:add-to-cart="afficheProduit(element)"></groupe-produit>
                    <sous-menu v-if="element.type==='menu'" :menu="element" :key="element.id"></sous-menu>
                </template>
            </div>
        </div>
        <v-spacer></v-spacer>
        <frise v-if="portail && !portail.frise_desactivee"></frise>
    </div>
</template>
<script>
import Api from '../api/api';
import SousMenu from '../components/SousMenu';
import ElementProduit from '../components/ElementProduit';
import GroupeProduit from '../components/GroupeProduit';
import {mapActions, mapGetters} from "vuex";
import Frise from "../components/theme/Frise";
import Carousel from "../components/theme/Carousel";
import { mdiClose } from "@mdi/js";

export default {
    name: 'Boutique',
    components: {
        Frise,
        ElementProduit,
        GroupeProduit,
        SousMenu,
        Carousel
    },
    metaInfo: {
        /*title: 'My Example App',
        titleTemplate: '%s - Yay!',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }*/
    },
    data: () => ({
        urlContenu:   process.env.VUE_APP_API_URL + '/menu/contenu',
        urlRecherche: process.env.VUE_APP_API_URL + '/boutique/recherche',
        elements: [],
        elementsPerPage: 21,
        page: 1,
        activeFilters: [],
        sortProperties: [
            {titre: 'Référence (A à Z)', prop: 'reference', reverse: false},
            {titre: 'Référence (Z à A)', prop: 'reference', reverse: true},
            {titre: 'Titre (A à Z)', prop: 'titre', reverse: false},
            {titre: 'Titre (Z à A)', prop: 'titre', reverse: true},
            {titre: 'Ordre d\'ajout', prop: undefined, reverse: false},
            {titre: 'Ordre d\'ajout décroissant', prop: undefined, reverse: true}
        ],
        elementSelectionne: {},
        selecteurQuantiteVisible: false,
        stockVisible: false,
        activeSort: '',
        notificationVisible: false,

        mdiClose
    }),
    computed: {
        ...mapGetters([
            'portail',
            'currentMenu',
            'currentRecherche',
            'boutiqueMenus',
            'allMenus',
        ]),
        /**
         * Retourne la liste de toutes les caractéristiques se trouvant dans le menu
         * actuel, peu importe la pagination, les filtres ou le tri
         */
        caracteristiques () {
            let caracteristiques = [];
            for (let element of this.elements) {
                if (!Object.hasOwnProperty.call(element, 'caracteristiques')) continue;
                for (let caracteristique of element.caracteristiques) {
                    let exists = caracteristiques.find(c => c.id === caracteristique.id);
                    if (!exists) {
                        caracteristiques.push(caracteristique);
                    }
                }
            }

            return caracteristiques.sort((a, b) => {
                if (a.ordre < b.ordre) return -1;
                if (a.ordre > b.ordre) return 1;
                return 0;
            });
        },
        /**
         * Retourne la liste des éléments après avoir appliqué les filtres
         * de caractéristiques sélectionnés (ou tous les éléments si aucun
         * filtre n'a été sélectionné)
         */
        filteredElements () {
            let elements = Object.assign([], this.elements);

            if (!this.allCaracteristiquesAreChecked) {
                elements = elements.filter(element => {
                    return this.activeFilters.every(caracteristique => {
                        return !Object.hasOwnProperty.call(element, 'caracteristiques')
                            || element.caracteristiques.find(carac => carac.id === caracteristique);
                    });
                });
            }

            return elements;
        },
        /**
         * Liste des ids de caractéristiques qui se trouvent toujours
         * dans les éléments après avoir été filtrés
         */
        leftCaracteristiquesIds () {
            let caracteristiques = [];
            for (let element of this.filteredElements) {
                if (!Object.hasOwnProperty.call(element, 'caracteristiques')) continue;
                for (let caracteristique of element.caracteristiques) {
                    let exists = caracteristiques.find(c => c === caracteristique.id);
                    if (!exists) {
                        caracteristiques.push(caracteristique.id);
                    }
                }
            }

            return caracteristiques;
        },
        /**
         * Retourne les éléments triés par rapport au tri sélectionné (ou tels quels si
         * aucun tri) après application des filtres de caractéristique
         */
        sortedElements () {
            let elements = Object.assign([], this.filteredElements);

            // Si on n'a aucun tri, on ne fait rien
            if (!this.activeSort) return elements;

            // Si la "prop" de tri n'est pas undefined
            if (this.activeSort.prop !== undefined) {
                let prop = this.activeSort.prop;

                // On compare cette prop en question
                elements = elements.sort((a, b) => {
                    if (a[prop] < b[prop]) return -1;
                    if (a[prop] > b[prop]) return 1;
                    return 0;
                })
            }
            else {
                // Le fait de trier sans fonction trie sur l'index (donc ordre d'ajout)
                elements = elements.sort();
            }
            // On inverse le résultat si Z vers A
            if (this.activeSort.reverse) {
                elements = elements.reverse();
            }

            return elements;
        },
        /**
         * Retourne une liste paginée des éléments de la sorte suivante :
         * [
         *  [ ... elements de la page 1 ... ],
         *  [ ... elements de la page 2 ... ],
         *  ...
         * ]
         */
        paginatedElements () {
            let paginated = [];

            for (let i = 0; i < this.sortedElements.length; i += this.elementsPerPage) {
                paginated.push(this.sortedElements.slice(i, i + this.elementsPerPage));
            }

            return paginated;
        },
        /**
         * Indique si le menu "Tout" est sélectionné ou non
         */
        allCaracteristiquesAreChecked () {
            return this.activeFilters.length === 0;
        },
        currentRecherche () {
            return this.$route.query.recherche;
        },
        /**
         * Calcule quel est le menu actuel
         */
        menuActuel () {
            if (this.currentMenu) return this.currentMenu;

            let menusHome = this.boutiqueMenus.filter(e => e.slug === 'home');

            if (menusHome.length > 0) return menusHome[0];
            return null;
        }
    },
    watch: {
        /**
         * Surveille le changement de menu et récupère les éléments du menu s'il a changé
         */
        currentMenu () {
            this.page = 1;
            this.getElements();
        },
        currentRecherche () {
            this.page = 1;
            this.getElements();
        }
    },
    methods: {
        ...mapActions([
           'setCurrentMenu',
           'setCurrentRecherche'
        ]),
        /**
         * Récupère les éléments du menu actuel et réinitialise les filtres de caractéristiques
         */
        getElements () {
            if (this.currentRecherche) {
                let data = new FormData();
                data.append('recherche', this.currentRecherche);

                Api.post(this.urlRecherche, data)
                    .then(this.setElements)
                    .then(this.toggleAllFilters);
            } else if (this.menuActuel) {
                Api.get(this.urlContenu + '/' + this.menuActuel.id)
                    .then(this.setElements)
                    .then(this.toggleAllFilters);
            }
        },
        /**
         * Affecte les élements du menu récupérés par la requête "getElements"
         * @param data
         * @returns {Promise<unknown>}
         */
        setElements (data) {
            this.elements = data;
            return Promise.resolve(data);
        },
        /**
         * Coche ou décoche l'état d'un filtre de caractéristique (selon son état)
         * @param filter
         */
        toggleFilter (filter) {
            if (this.activeFilters.includes(filter)) {
                this.activeFilters.splice(this.activeFilters.indexOf(filter), 1);
            }
            else {
                this.activeFilters.push(filter);
            }
        },
        /**
         * Active le filtre de caractéristique "Tout" (ce qui équivaut à aucun filtrage)
         */
        toggleAllFilters () {
            this.activeFilters = [];
        },
        /**
         * Retourne le nombre d'éléments qui comporte une caractéristique précise
         * @param caracteristique
         * @returns {number}
         */
        caracteristiqueCount (caracteristique) {
            let elements = Object.assign([], this.elements);

            elements = elements.filter(element => {
                return element.caracteristiques.some(carac => {
                    return carac.id === caracteristique.id;
                });
            });

            return elements.length;
        },
        /**
         * Vérifie si une caractéristique est présente dans les éléments filtrés affichés
         * @param caracteristique
         * @returns {boolean}
         */
        caracteristiqueExistsInElements (caracteristique) {
            return !this.leftCaracteristiquesIds.includes(caracteristique.id);
        },
        /**
         * Défini la propriété de tri des éléments
         * @param sortProperty
         */
        applySort (sortProperty) {
            this.activeSort = sortProperty;
        },
        produitAjoute() {
            this.fermeSelecteurQuantite();
            this.ouvrirNotification();
        },
        ouvreSelecteurQuantite(element) {
            this.elementSelectionne = element;
            this.selecteurQuantiteVisible = true;
        },
        fermeSelecteurQuantite() {
            this.selecteurQuantiteVisible = false;
        },
        afficheProduit(element) {
            this.$router.push({path: '/produit/' + encodeURIComponent(element.reference) }).catch(() => {})
        },
        restaureEtatDepuisURl() { //si la fenetre est rafraichi manuellement, le store est effacé, et ces données ne sont pas perdurée dans le navigateur.
            if(!this.currentMenu && !this.currentRecherche) {
                if (this.$route.query.menu) {
                    let currentMenu = this.allMenus.find(menu => menu.slug === this.$route.query.menu);
                    this.setCurrentMenu(currentMenu);
                } else if (this.$route.query.recherche) {
                    this.setCurrentRecherche(this.$route.query.recherche);
                }
            }
        },
        ouvrirNotification() {
            this.notificationVisible = true;
        },
        fermerNotification() {
            this.notificationVisible = false;
        }
    },
    mounted () {
        this.restaureEtatDepuisURl();
        if (typeof this.currentMenu === 'undefined') this.$router.push('/');
        //si on reload, les selections dans store ne sont pas perdurées, on les determine par le parametre dans l'url
        this.getElements();
    }
}
</script>
<style lang="scss">
.boutique {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F5F5F5;
    & .carousel {
        margin: 25px 0 0 0;
        padding: 0;
        height:200px;
        max-width:none;
    }
    & .categorie {
        max-width:1440px;
        padding:0;
        & .titre-boutique {
            margin: 25px 0;
            font: normal normal 600 35px/47px Nunito;
        }
        & .categorie-description {
            white-space: pre-wrap;
            padding: 20px;
            text-align: center;
            >p {
                margin-bottom: 0 !important;
            }
        }
        & .categorie-description-courte {
            padding: 20px;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            border-bottom: 0 solid #d6dadd;
        }
        & .categorie-filtre {
            padding: 10px !important;
        }

        & .categorie-contenu {
            max-width:1440px;
            display:flex;
            grid-gap:73px;
            margin: 15px auto;
        }
    }
}
</style>