<template>
    <div class="produit" v-if="ready">
        <back-img-portail  @click.stop="afficheProduit" :dist-url="elementVignetteUrl" class="produit-image">
        </back-img-portail>
        <h3 class="produit-title">{{ groupe.titre }}</h3>
        <h4 class="produit-desc" v-if="groupe.description" v-html="groupe.description"></h4>
        <div class="produit-prix">
            <span v-if="comportePrixMultiples(groupe)" class="apartir">A partir de</span>
            {{ prix }}
            <span v-if="quantiteMinimumAuPrixMinimum(groupe) > 1" class="apartir"> les {{ quantiteMinimumAuPrixMinimum(groupe) }}</span>
        </div>
    </div>
</template>
<script>

import MonnaieMixin from '../mixins/monnaie';
import {mapGetters} from "vuex";
import BackImgPortail from "./theme/BackImgPortail";
import ElementMixin from "../mixins/element";

export default {
    name: 'GroupeProduit',
    components: {
        BackImgPortail,
    },
    mixins: [
        MonnaieMixin,
        ElementMixin
    ],
    data: () => ({
        urlVignette:    process.env.VUE_APP_API_URL + '/image/vignette/grande',
        element: {},
        ready: false
    }),
    props: {
        groupe: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'panier',
            'portail'
        ]),
        elementVignetteUrl () {
            let vignette = this.groupe.vignette || this.element.vignette || 'nopicture.jpg';
            return this.urlVignette + '/' + encodeURI(vignette) + '?libelle=' + encodeURI(this.element.reference);
        },
        prix () {
            if (this.estArticleACompleter(this.groupe)) return 'Prix suivant contenu';
            if (this.prixMinimum(this.groupe) > 0) return this.enTTC(this.prixMinimum(this.groupe));
            return ' ';
        }
    },
    methods: {
        afficheProduit () {
            this.$emit('add-to-cart', this.groupe);
        }
    },
    mounted() {
        this.element = Object.assign({}, this.groupe.articles[0]);
        this.ready = true
    }
}

</script>
<style scoped lang="scss">
.produit {
    width: 305px;
    display: inline-block;
    position: relative;
    text-align: center;
    & .produit-image {
        cursor: pointer;
        background-position: center center;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        filter: drop-shadow(0 3px 6px #00000029);
        background-size: contain;
        background-repeat: no-repeat;
        height: 172px;
    }
    & .produit-title {
        padding-top: 10px;
        font: normal normal normal 25px/34px Nunito;
        height: 20px;
    }
    & .produit-desc {
        padding-top: 30px;
        font: normal normal normal 25px/34px Nunito;
        height: 20px;
    }
    & .produit-stock {
        padding: 5px 10px;
        margin: 0;
        //font-weight: normal;
        font-size: 10px;
        //display: block;
        font-weight: 400;
        overflow: hidden;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        color: #5e5e5e;
        position: absolute;
    }
    & .produit-prix {
        padding-top: 20px;
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        & .apartir {
            font-size: 0.80em;
        }
    }
    & .produit-select {
        padding-top: 20px;
        & .produit-quantite {
            display: inline-block;
            margin: 0;
            text-align: center;
            padding: 0 5px 0 20px;
            height: 20px;
        }
        & .produit-actions {
            display: inline-block;
            margin: 0;
            text-align: center;
            padding: 0 0 5px 0;
        }
    }
}

/*@media (min-width: 1600px) {
    .produit {
        width: 20%;
    }
}

@media (max-width: 1400px) and (min-width: 920px) {
    .produit {
        width: 50% !important;
    }
}

@media (max-width: 919px) {
    .produit {
        max-width:300px;
        width:100%;
    }
}*/
</style>