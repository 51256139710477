<template>
    <v-carousel
        cycle
        :class="'carousel '"
        :show-arrows-on-hover="!carouselUneImage"
        :show-arrows="!carouselUneImage"
        height="auto"
        :hide-delimiters="carouselUneImage"
        hide-delimiter-background
        :interval=5000
    >
        <template v-if="ready && carouselPersonnalise"> <!-- carousel avec images stockées dans les médias du portail -->
            <v-carousel-item
                v-for="(image,i) in carouselImg"
                :key="i"
            >
                <v-img :src="image"
                       :height="carouselHauteur"
                       width="100vw"
                ></v-img>
            </v-carousel-item>
        </template>
        <template v-else>
            <v-carousel-item
                v-for="(item,i) in carouselImgDefaut"
                :key="i"
            >
                <!-- Ce composant bug avec la hauteur automatique (une seule image affichée), pour compenser il faut spécifié l'aspect ratio -->
                <v-img :src="item.src"></v-img>
            </v-carousel-item>
        </template>
    </v-carousel>
</template>

<script>
import {mapGetters} from "vuex";
//import Api from "../../api/api";

export default {
    /*
     * Documentation:
     *
     * Le carousel s'affiche sur la page Home.
     *
     * Il peut contenir des images spécifique a un portail en définissant dans celui des médias
     * ayant les caractéristiques suivantes:
     *  - Ayant les même dimensions que l'image par défaut
     *  - Ayant la catégorie "eprint"
     *  - Ayant la référence "carousel"
     *
     * Ce composant vérifie l'existence de tels médias, si ce n'est pas le cas,
     * il affiche les images par défauts (liste ci-dessous)
     */

    name: "Carousel",
    data() {
        return {
            telechargeImgURL        : process.env.VUE_APP_API_URL + '/media/dl',

            imagesCarouselNeutreDir: '/img/carousel',
            imagesNeutres: [
                '1-plateforme_personnalisee.jpg',
                '2-personnalisation_en_ligne.jpg',
                '3-stock_et_logistique.jpg',
                '4-livraison.jpg',
            ],
            carouselImg: [],
            ready: false,
            window: {
                width: 0,
                height: 0
            },
        }
    },
    computed: {
        ...mapGetters([
            'bearer',
            'isLogged',
            'portail',
        ]),
        carouselImgDefaut() {
            let liste = [];
            this.imagesNeutres.forEach(file => liste.push({'src': this.imagesCarouselNeutreDir + '/' + file}));
            return liste;
        },
        carouselPersonnalise() {
            return this.carouselImg.length > 0;
        },
        carouselUneImage () {
            return this.carouselImg.length === 1;
        },
        carouselHauteur () {
            if (this.window.width > 1000)
                return '200px';
            else
                return this.window.width / 5;
        }
    },
    methods: {
        async chargeImagesPersonnalisees () { //celles-ci doivent figurer dans les médias, avoir un tag categorie nommé "eprint" et la référence "carousel"
            let images = this.portail.images;
            let carousel = [];
            for(let i = 0; i < images.length; i++) {
                let image = images[i];
                if (!Object.hasOwnProperty.call(image, 'reference')) continue;
                switch(image.reference) {
                    case 'carousel':
                        carousel.push(await this.chargeMedia(image.fichier));
                        break;
                    default:
                }
            }
            this.carouselImg = Object.assign([], carousel);
            this.ready = true;
        },
        async chargeMedia (image) {
            let url = this.telechargeImgURL + '/' + image;
            url += '?Bearer='+ this.bearer;
            if (this.canUseWebP())
                url += '&webp';
            return url;
            //let blob = await Api.getBlob(this.telechargeImgURL + '/' + image);
            //return URL.createObjectURL(blob);
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        canUseWebP() {
            let elem = document.createElement('canvas');
            if ((elem.getContext && elem.getContext('2d'))) {
                // was able or not to get WebP representation
                return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
            }
            // very old browser like IE 8, canvas not supported
            return false;
        }
    },
    mounted() {
        if (this.portail) this.chargeImagesPersonnalisees();
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss">
    .carousel {
        .v-carousel.v-carousel__hide-controls {
            & .v-carousel__controls {
                visibility: hidden;
            }
        }
        overflow: hidden;
    }
</style>