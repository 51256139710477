<template>
    <div class="category-item" @click.stop="parcourir(menu)">
        <back-img-portail :dist-url="menuVignetteUrl" class="category-image"></back-img-portail>
        <div class="category-title">
            {{ menu.libelle }}
        </div>
        <div class="category-actions">
            <btn-theme type="button" small title="Parcourir" @click="parcourir(menu)">Parcourir</btn-theme>
        </div>
    </div>
</template>
<script>

import BtnTheme from "./theme/BtnTheme";
import BackImgPortail from "./theme/BackImgPortail";

export default {
    name: 'SousMenu',
    components: {
        BackImgPortail,
        BtnTheme,
    },
    data: () => ({
        vignetteUrl: process.env.VUE_APP_API_URL + '/image/menu/',
    }),
    props: {
        menu: {
            /*
             * les propriétés de l'objet menu sont:
             * id
             * libelle
             * ordre
             * slug
             * vignette
             */
            type: Object,
            required: true
        }
    },
    computed: {
        menuVignetteUrl () {
            let vignette = this.menu.vignette || 'nopicture.jpg';
            return this.vignetteUrl + encodeURIComponent(vignette);
        }
    },
    methods: {
        parcourir(menu) {
            this.$store.dispatch('setCurrentMenu', menu)
            //le catch permet d'intercepter l'erreur qui se produit si on renvoi vers la même url (clic sur meme menu)
            this.$router.push({path: '/boutique', query: {menu: menu.slug}}).catch(() => {})
        }
    }
}

</script>
<style scoped lang="scss">
.category-item {
    width: 33.33%;
    display: inline-block;
    position: relative;
    outline: 1px solid #d6dadd;
    background: #f5f5f5;
    text-align: center;
    & .category-image {
        cursor: pointer;
        border-bottom: 1px solid #d6dadd;
        background-position: center center;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #ffffff;
        height: 250px;
        >img {
            max-width: 100%;
            width: auto\9;
            height: auto;
            vertical-align: middle;
            border: 0;
            -ms-interpolation-mode: bicubic;
        }
    }
    & .category-title {
        padding: 20px;
    }
    & .category-actions {
        margin: 0;
        text-align: center;
        padding-bottom: 30px;
    }
}
@media (min-width: 1600px) {
    .category-item {
        width: 25%;
    }
}

@media (max-width: 1400px) and (min-width: 920px) {
    .category-item {
        width: 50% !important;
    }
}
</style>