<template>
  <div :style="{ backgroundImage: `url(${dataUrl})` }" v-bind="$attrs" v-on="$listeners"></div>
</template>
<script>
import ImageMixin from './image';
export default {
    name: "BackImgPortail",
    mixins: [
        ImageMixin
    ],
}
</script>
