<template>
    <div class="frise-bas"  onerror="this.style.display='none'">
        <img :src="urlImg" decoding="async" alt="frise">
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Api from "../../api/api";

export default {

    /*
     * Documentation:
     *
     * La frise s'affiche sur la page Home et la page Boutique.
     *
     * Il peut contenir une image spécifique a un portail en définissant dans celui un média avec les critères suivants:
     *  - Ayant les même dimensions que l'image par défaut
     *  - Ayant la catégorie "eprint"
     *  - Ayant la référence "frise"
     *
     * Ce composant vérifie l'existence d'un tel média, si ce n'est pas le cas,
     * il affiche l'image par défaut (/img/Frise_Bas.png)
     */

    name: "Frise",
    data() {
        return {
            telechargeImgURL        : process.env.VUE_APP_API_URL + '/media/dl',
            imgPersonnalisee: null,
        }
    },
    computed: {
        ...mapGetters([
            'portail',
        ]),
        urlImg () {
            if (!this.imgPersonnalisee) return "/img/frise.jpg";
            return this.imgPersonnalisee;
        }
    },
    methods: {
        async chargeImagePersonnalisee () { //celle-ci doit figurer dans les médias, avoir un tag categorie nommé "eprint" et la référence "frise"
            let images = this.portail.images;
            let frise = images.find(image => image.reference === 'frise');
            if (frise) this.imgPersonnalisee = await this.chargeMedia(frise.fichier);
        },
        async chargeMedia (image) {
            let blob = await Api.getBlob(this.telechargeImgURL + '/' + image);
            return URL.createObjectURL(blob);
        },
    },
    mounted() {
        if (this.portail) this.chargeImagePersonnalisee();
    }
}
</script>

<style scoped lang="scss">
.frise-bas {
        margin-top: 20px;
        width: 100%;
        position: relative;
        height: 250px;
    >img{
        max-width: fit-content;
        width: auto;
        margin: auto;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
@media (max-width:920px) {
    .frise-bas {
        display:none;
    }
}
</style>